import React from 'react';
/* This example requires Tailwind CSS v2.0+ */
import {
  NewspaperIcon,
  PhoneIcon,
  SupportIcon,
  ArrowNarrowRightIcon,
} from '@heroicons/react/outline';
import { Link } from 'gatsby';
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image';

export default function Example({
  featured,
  bg,
  headline,
  text,
  btnURL,
  btnTxt,
}) {
  const supportLinks = featured;

  return (
    <div className="bg-white">
      {/* Header */}
      <div className="relative pb-32 bg-gray-800">
        <div className="absolute inset-0 overflow-hidden">
          <GatsbyImage
            className="w-full h-full object-cover"
            image={bg.childImageSharp?.gatsbyImageData}
            alt="image"
          />

          <div
            className="absolute inset-0 bg-gray-500 mix-blend-multiply"
            aria-hidden="true"
          />
        </div>
        <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
          <h1 className="text-4xl font-extrabold tracking-tight text-white md:text-5xl lg:text-6xl">
            {headline}
          </h1>
          <p className="mt-6 max-w-3xl text-xl text-white bg-red-800/70 p-4 md:p-10 rounded-md shadow-md">
            {text}
          </p>
          <Link
            to={btnURL}
            className="my-10  inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-black hover:text-white bg-white hover:bg-red-800 focus:outline-none focus:ring-2 focus:ring-offset-2 "
          >
            {btnTxt}{' '}
            <span className="pl-2" aria-hidden="true">
              {' '}
              &rarr;
            </span>
          </Link>
        </div>
      </div>

      {/* Overlapping cards */}
      <section
        className="-mt-32 max-w-7xl mx-auto relative z-10 pb-32 px-4 sm:px-6 lg:px-8"
        aria-labelledby="contact-heading"
      >
        <h2 className="sr-only" id="contact-heading">
          Contact us
        </h2>
        <div className="grid grid-cols-1 gap-y-20 lg:grid-cols-3 lg:gap-y-0 lg:gap-x-8 bg-red-">
          {supportLinks.map((link) => (
            <div
              key={link.name}
              className="flex flex-col bg-white rounded-2xl shadow-xl"
            >
              <div className="flex-1 relative pt-16 px-6 pb-8 md:px-8">
                <div
                  className={`absolute top-0 p-5 inline-block bg-${link.color} rounded-xl shadow-lg transform -translate-y-1/2`}
                >
                  <img
                    src={link.icon}
                    className="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                </div>
                <h3 className={`text-xl font-medium text-${link.color}`}>
                  {link.name}
                </h3>
                <p className="mt-4 text-base text-gray-500">
                  {link.description}
                </p>
              </div>
              <div className="p-6 bg-gray-50 rounded-bl-2xl rounded-br-2xl md:px-8">
                <a
                  href={link.href}
                  className={`text-base font-medium text-${link.color} hover:text-${link.color}`}
                >
                  {link.btnTxt}
                  <span aria-hidden="true"> &rarr;</span>
                </a>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
}

import React from 'react';
import { graphql, StaticQuery, Link } from 'gatsby';

const AreaServiceConnect = (props) => (
  <div className="mx-auto max-w-screen-lg service-connect not-prose">
    <h4 className="text-center text-base font-semibold uppercase text-gray-600 tracking-wider">
      {props.area} HVAC Services Offered By LaSalle Heating And Air
      Conditioning:
    </h4>
    <div className="grid grid-cols-1 md:grid-cols-2 md:grid-cols-3 gap-12 p-6 md:p-10">
      {props.data.allMdx.edges.map((edge, i) => (
        <div
          key={`${i}-${edge.node.id}`}
          className="shadow-lg rounded-md overflow-hidden"
        >
          <Link to={edge.node.frontmatter.path} className="no-underline">
            <div className="card service">
              <div className="card-content">
                <div
                  className={`bg-${edge.node.frontmatter.color} h-full  w-full grid items-center justify-center text-center p-5`}
                >
                  <img
                    className="w-1/3 mx-auto"
                    src={edge.node.frontmatter.image}
                    alt={props.area + ' ' + edge.node.frontmatter.title}
                  />
                </div>
                <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                  <h4 className="not-prose text-xl font-semibold text-gray-900 text-left">
                    {props.area} {edge.node.frontmatter.title}
                  </h4>
                  <p className="mt-3 text-base text-gray-500">
                    {edge.node.excerpt}
                  </p>
                </div>
              </div>
            </div>
          </Link>
        </div>
      ))}
    </div>
  </div>
);

export default (props) => (
  <StaticQuery
    query={graphql`
      query {
        allMdx(
          filter: { fileAbsolutePath: { regex: "/services/" } }
          sort: { fields: [frontmatter___date], order: DESC }
        ) {
          edges {
            node {
              id
              frontmatter {
                image
                path
                color
                title
                date(formatString: "DD MMMM YYYY")
              }
              excerpt
            }
          }
        }
      }
    `}
    render={(data) => <AreaServiceConnect area={props.area} data={data} />}
  />
);

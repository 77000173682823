import React from 'react';
import { Link } from 'gatsby';

class AreaFooterCTA extends React.Component {
  render() {
    return (
      <div className={'intro-small full-width' + ' ' + this.props.className}>
        <div className="container22 white-alpha">
          <h1>{this.props.title}</h1>
          <div className="intro-p">{this.props.subTitle}</div>
          <div className="cta-buttons">
            <ul>
              <li className="button orange-bg reverse-text">
                <Link to="/hvac-service-installation-repair-scheduling">
                  Email Us
                </Link>
              </li>
              <li className="button red-bg reverse-text">
                <a href="tel:952-435-3633">Call Us: (952) 435-3633</a>
              </li>
              <li className="button blue-bg reverse-text">
                <Link to="/hvac-service-installation-repair-scheduling">
                  Chat/Text With Us
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default AreaFooterCTA;

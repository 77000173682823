module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"LaSalle Heating","start_url":"/","background_color":"#ff9900","theme_color":"#ff9900","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"17a8db37cb3b5e23f68549f6d2874bb4"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"/Users/Ryan/Dropbox/WebsiteDevelopment/Lasalle Heating/liveSite/gatsby-serif-theme/node_modules/gatsby-remark-images","id":"bf268415-fa31-559f-b1d7-be99d2cef45f","name":"gatsby-remark-images","version":"6.13.0","modulePath":"/Users/Ryan/Dropbox/WebsiteDevelopment/Lasalle Heating/liveSite/gatsby-serif-theme/node_modules/gatsby-remark-images/index.js","pluginOptions":{"plugins":[],"maxWidth":1200,"quality":"50","withWebp":"true","loding":"lazy"},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]},{"resolve":"/Users/Ryan/Dropbox/WebsiteDevelopment/Lasalle Heating/liveSite/gatsby-serif-theme/node_modules/@pauliescanlon/gatsby-remark-grid-system","id":"7719feb9-84ce-5707-a15c-b253519c384e","name":"@pauliescanlon/gatsby-remark-grid-system","version":"0.0.4","modulePath":"/Users/Ryan/Dropbox/WebsiteDevelopment/Lasalle Heating/liveSite/gatsby-serif-theme/node_modules/@pauliescanlon/gatsby-remark-grid-system/index.js","module":{},"pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":["onRenderBody"]}],"extensions":[".mdx"],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/Users/Ryan/Dropbox/WebsiteDevelopment/Lasalle Heating/liveSite/gatsby-serif-theme","commonmark":false},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1200,"quality":"50","withWebp":"true","loding":"lazy"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-50304018-1","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]

import React from 'react';
import { Link } from 'gatsby';

class HeroServices extends React.Component {
  render() {
    return (
      <div
        className={
          'max-w-7xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8' +
          ' ' +
          this.props.className
        }
      >
        <div className="max-w-4xl mx-auto">
          <h2 className="text-base font-semibold uppercase tracking-wider text-gray-500">
            {this.props.title}
          </h2>
          <div className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            {this.props.subTitle}
          </div>
          <div className="max-w-3xl mx-auto">
            <p className="mt-4 text-lg leading-6">
              Schedule Your HVAC Service, Intallation, or Repair by:
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-7 py-20">
              <div className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-orange-600 hover:bg-orange-700">
                <Link to="/hvac-service-installation-repair-scheduling">
                  HVAC service by email
                </Link>
              </div>
              <div className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-orange-500 hover:bg-orange-600">
                <a href="tel:952-435-3633">
                  HVAC service by phone: (952) 435-3633
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HeroServices;

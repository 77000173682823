import React from 'react';
import { graphql, StaticQuery, Link } from 'gatsby';

const ServiceToAreasConnect = (props) => (
  <div className="row area-service-connect">
    <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
      <h4 className="text-center text-base font-semibold uppercase text-gray-600 tracking-wider">
        {props.area} LaSalle Heating And Air Conditioning Services The Following
        Cities:{' '}
      </h4>
      <div className="mt-6 grid grid-cols-2 gap-0.5 md:grid-cols-5 lg:mt-8">
        {props.data.allMdx.edges.map((edge, i) => (
          <div
            key={`${i}-${edge.node.frontmatter.path}`}
            className="col-span-1 flex justify-center py-8 px-8 bg-gray-50 rounded-lg hover:bg-gray-200 transition-colors"
          >
            <Link to={edge.node.frontmatter.path}>
              <div className="card service">
                <div className="text-center">
                  <img
                    className="max-h-12 mx-auto mb-4"
                    src={edge.node.frontmatter.image}
                    alt={props.area + ' ' + edge.node.frontmatter.title}
                  />
                  <h4 className="text-xl font-semibold text-gray-900">
                    {props.area} {edge.node.frontmatter.title}
                  </h4>
                  <h5 className="text-xs">{edge.node.excerpt}</h5>
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  </div>
);

export default (props) => (
  <StaticQuery
    query={graphql`
      query {
        allMdx(
          filter: { fileAbsolutePath: { regex: "/areas/" } }
          sort: { fields: [frontmatter___date], order: DESC }
        ) {
          edges {
            node {
              id
              frontmatter {
                image
                path
                title
                date(formatString: "DD MMMM YYYY")
              }
              excerpt
            }
          }
        }
      }
    `}
    render={(data) => <ServiceToAreasConnect area={props.area} data={data} />}
  />
);
